import { types } from "@redux/types/types";

const initialState = {
    registers: [],
    endPoint: null,
    checkRows: [],
    filters: {},
    options: {},
    filterValues: {},
    count: 0,
    page: 1,
    limit: 25,
    sortField: null,
    sort: null,
};

const handleNestedState = (keys, initialData, action) => {
    const nested = (data, index = 0) => {
        if (index === keys.length - 1) {
            if (action.payload.removeRepeater) {
                return {
                    ...data,
                    [keys[index]]: [...data[keys[index]].filter((f, i) => i !== action.payload.position)],
                };
            } else if (action.payload.addRepeater) {
                return { ...data, [keys[index]]: [...data[keys[index]], action.payload.value] };
            }
        }
        if (index === keys.length) {
            if (action.payload.trim) {
                return {
                    ...data,
                    [action.payload.name]: [
                        ...data[action.payload.name].filter((f) => !!!action.payload.value.some((a) => a === f)),
                    ],
                };
            }
            if (action.payload.merge) {
                return { ...data, [action.payload.name]: [...data[action.payload.name], ...action.payload.value] };
            }
            if (action.payload.enumeration) return { ...data, [action.payload.name]: action.payload.value };
            if (action.payload.customMTM) return [...action.payload.value.map((a) => ({ id: a.value, name: a.label }))];
            return { ...data, [action.payload.name]: action.payload.value };
        }
        if (index !== keys.length - 1 && data[keys[index]] instanceof Array) {
            data[keys[index]][keys[index + 1]] = nested(data[keys[index]][keys[index + 1]], index + 2);
            return { ...data, [keys[index]]: [...data[keys[index]]] };
        }
        return { ...data, [keys[index]]: nested(data[keys[index]], ++index) };
    };
    return nested(initialData);
};

const list = (state = initialState, action) => {
    switch (action.type) {
        case types.editCollectionRegister:
            return { ...handleNestedState(action.payload.key, state, action) };
        case types.load:
            // If server returns an error, you can handle it here
            if (!action.payload.data) action.payload.data = [];
            return {
                ...state,
                endPoint: action.payload.endPoint,
                count: action.payload.count,
                registers: [...action.payload.data],
            };
        case types.delete:
            return {
                ...state,
                registers: state.registers.filter((reg) => reg.id !== action.payload),
            };
        case types.deleteRepair:
            state.registers[action.payload.index].repairs = state.registers[action.payload.index].repairs.filter(
                (reg) => reg.id !== action.payload.id,
            );

            return {
                ...state,
                registers: [...state.registers],
            };
        case types.cleaningAll:
            return {
                ...initialState,
            };
        case types.change:
            const { index, name, valor } = action.payload;

            const newReg = {
                ...state.registers[index],
                [name]: valor,
            };

            return {
                ...state,
                registers: state.registers.map((reg, i) => (i === index ? newReg : reg)),
            };

        case types.halfLoad:
            return {
                ...state,
                registers: state.registers.map((reg) => {
                    return reg.id === action.payload.parentId ? { ...reg, details: action.payload.data } : reg;
                }),
            };
        case types.checkRows:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };

        case types.updateRepairStatus:
            return {
                ...state,
                registers: state.registers.map((reg) => {
                    return reg.id === action.payload.id
                        ? { ...reg, isBeingRepaired: action.payload.isBeingRepaired }
                        : reg;
                }),
            };

        default:
            return state;
    }
};

export default list;
